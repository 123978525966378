import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ResponseModel } from '@app/core/models/response.model';
import { environment } from '@env/environment';

import { switchMap, of, Observable } from 'rxjs';

@Injectable()
export class ReportService {
	private URL: string = environment.apiUrl + 'reports/';

	/**
	 * Constructor
	 */
	constructor(@Inject(HttpClient) private http: HttpClient) {}

	reportUsersStatusAnalysis(request: {
		startDate: Date;
		endDate: Date;
	}): Observable<{ pdf: string; excel: string }> {
		return this.http
			.post(this.URL + 'report-users-status-analysis', request)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<{ pdf: string; excel: string }>;
					return of(res.data);
				}),
			);
	}

	reportUsersRegistrationSummary(request: {
		startDate: Date;
		endDate: Date;
	}): Observable<{ pdf: string; excel: string }> {
		return this.http
			.post(this.URL + 'report-users-registration-summay', request)
			.pipe(
				switchMap((response: any) => {
					const res = response as ResponseModel<{ pdf: string; excel: string }>;
					return of(res.data);
				}),
			);
	}

	reportCompaniesLocations(request: {
		startDate: Date;
		endDate: Date;
	}): Observable<{ pdf: string; excel: string }> {
		return this.http.post(this.URL + 'report-company-locations', request).pipe(
			switchMap((response: any) => {
				const res = response as ResponseModel<{ pdf: string; excel: string }>;
				return of(res.data);
			}),
		);
	}
}
